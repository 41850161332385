.App {
  padding: 20px 10px;
  @media only screen and (min-width: 992px) {
    padding: 20px 40px;
  }
}

/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
.logo_container {
  text-align: center;
}

.filterContainer {
  display: flex;
 
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    justify-content: center;
  }
}

.searchContainer {
  display: flex;

  flex-direction: column;
  @media only screen and (min-width: 992px) {
    margin-left: 50px;
    width: 80%;
    align-items: center;
    align-self: center;
    flex-direction: row;
  }
}

.searchContainer div:first-child {
  @media only screen and (min-width: 992px) {
    width: 80%;
  }
}
.searchContainer #code, #extra-select {
    width: 100%;
    padding: 12px 0px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    @media only screen and (min-width: 992px) {
      padding: 12px 20px;
    }
}

.searchContainer button {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 0px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
   
    @media only screen and (min-width: 992px) {
      padding: 14px 20px;
      margin-left: 20px;
      width: 40%;
    }
  }
  
.searchContainer button:hover {
    background-color: #45a049;
  }

  .extra_container {
    @media only screen and (min-width: 992px) {
      width: 40%;
    }
  }
  table {
    margin-top: 60px;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }
  
  th, td {
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even){background-color: #f2f2f2}

  table tfoot {
    border-top: 2px solid #ddd;
  }
  table tfoot td {
    font-weight: 700;
  }

  .message {    
      padding: 20px;
      background-color: #ff9800;
      color: white;
      font-size: 14px;
  }